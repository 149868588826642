.blog-detail img{
    width: 100%;
    height: 50vh;
    margin-top: 40px;
}

.cabs-date{
    margin-bottom: 15px;
    color: rgb(89, 89, 89);
    font-weight: 600;
    font-size: 1.2rem;
}

.blog-detail .desp{
    font-size: 1rem;
    color: var(--black);
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin-bottom: 40px;
}

.blog-detail .title {
    font-size: 30px;
    color: var(--yellow);
    font-family: 'Poppins', sans-serif;
    margin: 1rem 0;
}

.blog-detail .tags{
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--black);
    margin: 1rem 0;
}

.blog-detail .tags .cabs-tag{
    border-radius: 5px;
    margin-top: 10px;
    color: var(--bg-color);
    background-color: var(--yellow);
    font-size: 1.2rem;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    padding: 0 5px;
}