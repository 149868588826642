.benefits__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.benefits__left {
  width: 50%;
  padding: 2rem;
}

.benefits__left h2 {
  font-size: 36px;
  color: var(--yellow);
  font-family: "Poppins", sans-serif;
}

.benefits__left p {
  font-size: 18px;
  color: var(--light-color);
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
}

.benefits__right .benefits__point {
  display: flex;
  color: var(--yellow);
  font-size: 2rem;
  padding: 5px;
  justify-content: space-evenly;
}

.benefits__right .benefits__point h4 {
  color: var(--light-color);
  width: 90%;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .faq__box {
    width: 90%;
  }
  .benefits__container {
    flex-direction: column;
  }
  .benefits__left h2{
    font-size: 24px;
  }
  .benefits__left,.benefits__right{
    width: 100%;
  }
}
