.blog{
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.blog__container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    flex-wrap: wrap;
    gap: 50px;
    margin-bottom: 3rem;
}
.blog .title {
    font-size: 30px;
    color: var(--yellow);
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-top: 2rem;
}