.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.loader__road{
    position: relative;
    width: 700px;
    height: 150px;
    background-color: #333;
    border-radius: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style:preserve-3d ;
    perspective: 500px;
}
.loader__road::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #fff, #fff 50%, transparent 50%, transparent 100%);
    background-size: 50px;
    animation: roadAnimation 0.125s linear infinite;
}
.loader__taxi{
    position: absolute;
    width: 200px;
    height: 80px;
    background-color: #f4b603;
    border-radius: 10px;
    box-shadow: -10px 10px rgba(0,0,0,0.25);
    transform-style:preserve-3d ;
    perspective: 800px;
}
.loader__taxi::before{
    content: '';
    position: absolute;
    width: 5px;
    height: 15px;
    top: 12px;
    left: 2px;
    border-radius: 2px;
    background: #fc3c25;
    box-shadow: 0 42px #fc3c25;
}
.loader__taxi::after{
    content: '';
    position: absolute;
    width: 6px;
    height: 15px;
    top: 12px;
    right: 2px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 42px #fff;
}
.loader__taxi span{
    position: absolute;
    inset: 5px 10px;
    background: #fdd206;
    border-radius: 10px;
    overflow: hidden;
}
.loader__taxi span::before{
    content: 'Taxi';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(90deg);
    background-color: #fff;
    font-size: 0.75em;
    font-weight: 1000;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #000;
    z-index: 10;
}
.loader__road span b{
    position: absolute;
    inset: 0 30px;
    background: #111;
    border-radius: 10px;
}
.loader__road span b::before{
    content: '';
    position: absolute;
    inset: 6px 25px;
    background: #fdd206;
    border-radius: 5px;
}
.loader__road span b::after{
    content: '';
    position: absolute;
    inset: 0px 58px;
    background: #fdd206;
    border-radius: 5px;
}
.loader__road span i{
    position: absolute;
    inset: 0;
}
.loader__taxi span i::before{
    content: '';
    position: absolute;
    top: 7px;
    left: 20px;
    width: 40px;
    height: 5px;
    background: #fdd206;
    transform-origin: right;
    transform: rotate(15deg); 
    box-shadow: 100px 34px #fdd206;
}
.loader__taxi span i::after{
    content: '';
    position: absolute;
    bottom: 7px;
    left: 20px;
    width: 40px;
    height: 5px;
    background: #fdd206;
    transform-origin: right;
    transform: rotate(-15deg); 
    box-shadow: 100px -34px #fdd206;
}
.loader__road .light{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 70px;
    background-color: #0002;
    border-radius: 15px;
    transform-style: preserve-3d;
}
.loader__road .light::before{
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 150px;
    height: 16px;
    background: linear-gradient(90deg, #fff, transparent);
    transform-origin: left;
    transform: perspective(500px) rotateY(-50deg);
}
.loader__road .light::after{
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 150px;
    height: 16px;
    background: linear-gradient(90deg, #fff, transparent);
    transform-origin: left;
    transform: perspective(500px) rotateY(-50deg);
}
@keyframes roadAnimation{
    0%{
        background-position: 50px 0 ;
    }
    100%{
        background-position: 0 0;
    }
}

@media (max-width: 700px) {
    .loader__road{
        width: 90%;
    }
}