.footer {
    color: var(--black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--bg-color);
    padding: 1rem 2rem;
    box-shadow: var(--box-shadow);
  border-radius: 10px;
  margin-bottom: 20px;
}

.footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.footer-heading h1 {
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}

.footer-heading p {
    box-sizing: border-box;
    font-size: 40px;
    line-height: 21px;
    color: var(--black);
    word-spacing: 2px;
}

.footer__links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.footer__links div {
    width: 250px;
    margin: 1rem;
}

.footer__links_logo {
    display: flex;
    flex-direction: column;
}

.footer__links_logo img {
    width: 130px;
    height: 40px;
    margin-bottom: 1rem;
}

.footer__links_logo p {
    font-size: 12px;
    line-height: 14px;
    color: var(--black);
}

.footer__links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
}

.footer__links_div h4 {
    font-size: 20px;
    line-height: 16px;
    color: var(--yellow);
    margin-bottom: 1rem;
}

.footer__links {
    display: flex;
    font-size: 16px;
    justify-content: space-evenly;
}

.footer__link {
    position: relative;
    width: 100%;
}

.footer__links_div a{
    margin-bottom: 10px;
}

.footer__link a:hover ,.footer__links_div a:hover{
    color: var(--yellow);
}

.footer__links_div a .i {
    margin-right: 10px;
}

.footer__copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footer__copyright p {
    font-size: 14px;
    line-height: 14px;
    color: var(--black);
    padding-bottom: 14px;
}

@media screen and (max-width: 550px) {
    .footer__links div {
        margin: 1rem 0;
        width: 100%;
    }
}