.bigCard {
  width: 40%;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.bigCard h3 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: var(--yellow);
}
.bigCard p {
  font-size: 0.8rem;
  line-height: 1.8;
  color: var(--light-color);
}

@media (max-width: 900px) {
    .bigCard {
        width: 90%;
        margin-bottom: 3rem;
    }
}