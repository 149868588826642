.carousal {
    position: relative;
    height: 70vh;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 20px;
    box-shadow: var(--box-shadow);

}

.carousal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #000000ab;
}

.carousal-img {
    min-width: 100%;
    width: 100%;
    object-fit: cover;
}

.carousal h2 {
    width: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    color: var(--yellow);
    margin: 1rem 0;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #fff;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #fff;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 2s ease;
}

.carousal .slide.active {
    opacity: 1;
    transition-duration: 2s;
    transform: scale(1.18);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.carousal-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carousal-title {
    color: #fff;
}

.carousal-desp {
    width: 50%;
    color: #fff;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    padding: 9px;
}

.slide.active a button {
    width: 150px;
    height: 38px;
    border-radius: 5px;
    margin-top: 10px;
    color: var(--bg-color);
    background-color: var(--yellow);
    font-size: 20px;
    outline: none;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    margin-top: 10px;
}

.slide.active a button:hover {
    background-color: var(--light-color);
}

@media (max-width: 700px) {
    .carousal {
        height: 80vh;
    }
    .slide {
        height: 100%;
    }

    .slide.active {
        height: 100%;
    }

    .slide.active img {
        height: 100%;
    }

    .right-arrow {
        right: 12px;
        font-size: 2rem;
    }

    .left-arrow {
        left: 12px;
        font-size: 2rem;
    }

    .carousal-title {
        width: 80%;
        text-align: center;
    }

    .carousal-desp {
        width: 70%;
    }
}