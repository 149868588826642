.our__services h2 {
  width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  color: var(--yellow);
}

@media (max-width: 900px) {
  .card__container {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 700px){
  .our__services h2{
      font-size: 28px;
  }

  }