.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 10px 5px;
}
.header__left{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__logo {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.theme__toggler {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 2rem;
  text-align: center;
  box-shadow: var(--box-shadow);
  color: var(--black);
  border-radius: 50%;
}

.header__logo img {
  height: 100%;
  width: 100%;
  padding: 10px 20px;
}

.header__links {
  display: flex;
  width: 60%;
  font-size: 16px;
  justify-content: space-evenly;
  position: relative;
}

.header__link {
  position: relative;
}

.header__link a:hover {
  color: var(--yellow);
}

.header__link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: var(--yellow);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

/* .header__link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
} */

.dropdown {
  display: none;
}

.header__link:hover .dropdown {
  position: absolute;
  display: block;
  width: 250px;
  background: var(--bg-color);
  z-index: 200;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.header__link:hover .dropdown li {
  padding: 10px;
}

.menu-btn {
  display: none;
}
.float{
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 1.5rem;
  text-align: center;
  box-shadow: var(--box-shadow);
  color: var(--black);
  border-radius: 50%;
  margin-left: 20px;
}
@media only screen and (max-width: 990px) and (min-width: 700px) {
  .header__links {
    width: 90%;
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .header {
    margin-top: 2rem;
    padding: 10px;
    width: 90%;
  }
  .header__logo {
    width: 150px;
    height: 45px;
  }
  .header__links {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: 99;
    background: var(--bg-color);
    width: 100%;
    border-right: var(--border);
    border-right-width: 3px;
    bottom: 0;
    transform: translateX(700px);
    text-align: center;
    font-size: 20px;
  }
  .header__link:hover .dropdown li {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .header__link:hover .dropdown {
    position: absolute;
    transform: translate(-40%);
    width: 300px;
  }
  .header__link:hover .dropdown li {
    font-size: 1.2rem;
  }
  .header__links.active {
    transform: translateX(0);
  }
  .header__link {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  .menu-btn {
    display: block;
    right: 30px;
    font-size: 2rem;
    z-index: 100;
    color: var(--black);
    cursor: pointer;
  }
}
@media (max-width: 767px) {
  .header__left {
    justify-content: space-between;
    width: 80%;
}
  .float{
    position:relative;
    width:40px;
    height:40px;
    background-color: var(--yellow);
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .my-float{
    font-size: 25px;
    z-index: 10000;
    display: block;
  }
  
  }