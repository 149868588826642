.banner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner .left_container {
    width: 50%;
    padding: 2rem;
}

.banner .right_container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
}

.banner .right_container img{
    width: 100%;
}

.banner h2 {
    font-size: 36px;
    color: var(--yellow);
    font-family: 'Poppins',sans-serif;
}

.banner p {
    font-size: 18px;
    color: var(--light-color);
    margin: 15px 0;
    font-family: 'Poppins',sans-serif;
}

.banner button {
    width: 200px;
    height: 48px;
    border-radius: 5px;
    margin-top: 10px;
    color: var(--bg-color);
    background-color: var(--yellow);
    font-size: 20px;
    outline: none;
    border: none;
    font-family: 'Poppins',sans-serif;
    cursor: pointer;
}

.download-btns{
    display: flex;
    gap: 10px;
}

button img{
    display: none;
}

.download-btns button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 15px;
}

.download-btns .left-btn{
    display: flex;
    align-items: center;
    margin: 0;
}

.download-btns img{
    display: block;
    width: 150px;
    min-height: 50px;
}

.banner button:hover{
    background-color: var(--light-color);
}

@media (max-width: 900px) {
    .banner{
        flex-direction: column-reverse;
    }

    .banner .left_container ,.banner .right_container {
        width: 100%;
    }
}
@media (max-width: 700px) {
.banner h2 {
    font-size: 24px;
}
.banner button{
    width: 100%;
    margin-left: 0 ;
}

.download-btns {
    gap: 0px; 
    flex-direction: column;
}
}