/* ----------- about us --------------- */

.about_us{
    margin-top: 2rem;
}
.about_us h2{
    width: 100%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    color: var(--yellow);
    margin-top: 2rem;
}
.about_us .banner h2{
    text-align:left;
    font-size: 40px;
}
.timeline{
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
}
.timeline::after{
    content: ' ';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
    box-shadow: var(--box-shadow);
    animation: moveline 6s linear forwards;
}
@keyframes moveline{
    0%{
        height: 0%;
    }
    100%{
        height: 100%;
    }
}
.cont{
    padding: 10px 50px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
}
@keyframes movedown{
    0%{
        opacity: 1;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translate(0px);
    }
}
.cont:nth-child(1){
    animation-delay: 0s;
}
.cont:nth-child(2){
    animation-delay: 1s;
}
.cont:nth-child(3){
    animation-delay: 2s;
}
.text_box{
    padding: 20px 30px;
    background-color: #fff;
    position: relative;
    border-radius: 6px;
    font-style: 18px;
    margin-top: 10px;
    font-family: NunitoRegular;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
}
.cont.left{
    left: 0;
}
.cont.right{
    left: 50%;
}
.left_arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #fff;
    right: -15px;
}
.right_arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    left: -15px;
}

@media (max-width:900px) {
     /* -------- about us--------- */
     .timeline{
        margin: 50px auto;
    }
    .timeline::after{
        left: 31px;
    }
    .cont{
        width: 100%;
        padding-left: 80px;
        padding-right:25px ;
    }
    .text_box{
        font-style: 18px;
    }
    .cont.right{
        left: 0;
    }
    .left_arrow, .right_arrow{
        border-right: 15px solid #fff;
        border-left:  0;
        left: -15px;
    }
}

@media (max-width:700px) {

    .about_us h2 ,.about_us .banner h2{
        font-size: 28px;
    }

    .banner p {
        font-size: 14px;
    }
}